export const messages = {
  en: {
    message: {
      hello: 'hi world'
    }
  },
  es: {
    message: {
      hello: 'hola mundo'
    }
  }
}